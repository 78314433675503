import { SET_DEALER_CODE } from '../actions/types';

export default function dealerCode(state = '', action) {
	switch (action.type) {
		case SET_DEALER_CODE:
			return action.payload;
		default:
			return state;
	}
}
