import React from 'react';
import { useSelector } from 'react-redux';
import getLogoInfo from '../utils/getLogoInfo';

import Logo from './Logo';

const ProgramLogos = () => {
	const { dealerCode, programLogos } = useSelector((state) => state);
	const logoInfo = getLogoInfo(dealerCode);

	return (
		<>
			{programLogos.map((flag) => {
				const { alt, link, target, params } = logoInfo[flag];

				return (
					<Logo
						key={flag}
						href={link}
						params={params}
						target={target}
						className={`${flag} pt-4 px-4 pb-3 lazy-image`}
						placeholderSrc="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
						src={`/static/assets/images/logos/oem-subaru/footer-logo-${flag}.png`}
						alt={alt}
					/>
				);
			})}
		</>
	);
};

export default ProgramLogos;
