import React from 'react';
import PropTypes from 'prop-types';
import { useLabels, useSitemap } from 'wsm-common-data';

const Logo = ({
	href,
	target,
	className,
	alt,
	src,
	placeholderSrc,
	params
}) => {
	const labels = useLabels();
	const sitemap = useSitemap();

	return (
		<a
			href={
				sitemap.has(href)
					? sitemap.get(href) + (params || '')
					: href + (params || '')
			}
			target={target}
		>
			<img
				className={className}
				data-src={src}
				src={placeholderSrc}
				alt={labels.get(alt)}
			/>
		</a>
	);
};

Logo.propTypes = {
	href: PropTypes.string.isRequired,
	target: PropTypes.string.isRequired,
	className: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired,
	src: PropTypes.string.isRequired,
	placeholderSrc: PropTypes.string.isRequired,
	params: PropTypes.string.isRequired
};

export default Logo;
