import { SET_PROGRAM_LOGOS } from '../actions/types';

export default function programLogos(state = [], action) {
	const acceptedFlags = [
		'lpgold',
		'lpcust',
		'lpcomm',
		'cpo',
		'estore',
		'eco',
		'express',
		'tradeupadv',
		'certifiedcoll'
	];
	if (!action.error && action.payload) {
		switch (action.type) {
			case SET_PROGRAM_LOGOS:
				return action.payload.filter((flag) =>
					acceptedFlags.includes(flag)
				);
			default:
				return state;
		}
	}

	return state;
}
