export default function getLogoInfo(dealerCode) {
	return {
		lpgold: {
			alt: 'LOVE_PROMISE_GOLD_AWARD',
			link: 'LOVE_PROMISE',
			params: '',
			target: '_self'
		},
		lpcust: {
			alt: 'LOVE_PROMISE_CUSTOMER_COMMITMENT_AWARD',
			link: 'LOVE_PROMISE',
			params: '',
			target: '_self'
		},
		lpcomm: {
			alt: 'LOVE_PROMISE_COMMUNITY_COMMITMENT_AWARD',
			link: 'LOVE_PROMISE',
			params: '',
			target: '_self'
		},
		cpo: {
			alt: 'CERTIFIED_PREOWNED',
			link: 'INVENTORY_LISTING_DEFAULT_AUTO_CERTIFIED_USED',
			params: '?invType=certified&SBmake=Subaru&reset=InventoryListing',
			target: '_self'
		},
		estore: {
			alt: 'SUBARU_PARTS_ONLINE',
			link: 'https://parts.subaru.com/',
			params: `?dealercode=${dealerCode}&partner=DDChome`,
			target: '_blank'
		},
		eco: {
			alt: 'CERTIFIED_SUBARU_ECOFRIENDLY_RETAILER',
			link: 'SITEBUILDER_ECO_FRIENDLY_RETAILER_PROGRAM_1',
			params: '',
			target: '_self'
		},
		express: {
			alt: 'SUBARU_EXPRESS_SERVICE',
			link: 'SITEBUILDER_SUBARU_EXPRESS_SERVICE_1',
			params: '',
			target: '_self'
		},
		tradeupadv: {
			alt: 'SUBRAU_TRADE_UP_ADVANTAGE',
			link: 'TRADE_UP_ADVANTAGE',
			params: '',
			target: '_self'
		},
		certifiedcoll: {
			alt: 'SUBRAU_CERTIFIED_COLLISION',
			link: 'SUBARU_CERTIFIED_COLLISION_CENTERS',
			params: '',
			target: '_self'
		}
	};
}
