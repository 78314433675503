import programLogos from './programLogos';
import dealerCode from './dealerCode';
import logoInfo from './logoInfo';

const root = {
	programLogos,
	dealerCode,
	logoInfo
};

export default root;
